<template>
<div class="">
  <div class="h-screen flex items-center justify-center relative lg:-mt-10 lg:-top-0 -top-10 px-4 sm:px-6 lg:px-8 ">
  <div class="max-w-md lg:max-w-sm w-full space-y-8">
    <div>
      <h2 class="text-center text-3xl font-extrabold text-gray-900">
        Exit Stage Left...
      </h2>
      <h2 class="mt-4 text-center text-2xl font-extrabold text-gray-900">
        <b>Check back soon - this page is not available yet</b><br>
       (Or something is broken!)<br>
      </h2>
      
      <router-link class="block" to="/">
        <button type="button" class="mt-6 group relative w-full flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Return Back To TBFF
          </button>
      </router-link>

    </div>
  </div>
</div>
</div>
</template>

<script lang="ts">

export default {
    name: '404',
    data () {
    return {
  }
},
components: {
},
  methods: {

  },
  mounted () {

  }
}
</script>